import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import WalletCard from "./WalletCard.jsx";
import React, { useState, Component, useEffect } from "react";
import {
  MenuItem,
  NativeSelect,
  Select,
  FormControl,
  InputLabel,
  Box,
  Button,
  Typography,
  Grid,
  Paper,
} from "@mui/material";
import Description from './Description.jsx'
import borpaBG from './images/borpaBGedited.png'
import abi from './contract/BORPADAO.json'
import { ethers } from 'ethers';
import Web3 from 'web3'
import bd from "./contract/BORPADAO.json"





const App = () => {
  const [contract, setContract] = useState(null);
  const [isMinting, setIsMinting] = useState(false);
  const [account, setAccount] = useState(null);
  const [chain, setChain] = useState(null);
  const [metamask, setMetamask] = useState(false);
  const [isWalletConnected, setIsWalletConnected] = useState(false);

  const [amountForSale, setAmountForSale] = useState(null);
  const [maxMintPerAddress, setMaxMintPerAddress] = useState(null);
  const [numberMinted, setNumberMinted] = useState(0)
  const [count, setCount] = useState(1)
  const [currentPrice, setcurrentPrice] = useState(0)
  const [totalBorpas, setTotalBorpas] = useState(0)
  const [remainingMint, setRemainingMint] = useState(0)
  const [remainingFree, setRemainingFree] = useState(0)

  const countHandler = (newCount) => {
    setCount(newCount)
  }
;


//hello

  useEffect(() => {
    if (contract != null && contract.methods != null && account != null) {
      contractReads();
    }
  }, [contract]);

  const contractReads = () => {
    contract.methods
      .amountForSale()
      .call()
      .then((response) => {
        setAmountForSale(response);
      });

      contract.methods
      .maxPerAddressDuringMint()
      .call()
      .then((response) => {
        setMaxMintPerAddress(response);
      });

    contract.methods
      .currentPrice()
      .call()
      .then((response) => {
        setcurrentPrice(response);
      });

      contract.methods
      .numberMinted(account)
      .call()
      .then((response) => {
        setNumberMinted(response);
      });

      contract.methods
      .remainingFree()
      .call()
      .then((response) => {
        setRemainingFree(response);
      });

    contract.methods
      .amountForSale()
      .call()
      .then((amount) => {
        setTotalBorpas(amount)
        contract.methods
          .totalSupply()
          .call()
          .then((supply) => {
            setRemainingMint(amount - supply);
          });
      });
  };

  const loadBlockchainData = async () => {
    const web3 = window.web3;

    const networkId = await web3.eth.net.getId();
    console.log(networkId)
    const networkData = process.env.REACT_APP_WEB3_API

    if (networkData) {
      const abi = bd;
      const address = process.env.REACT_APP_CONTRACT_ADDRESS;
      const contract = new web3.eth.Contract(abi, address);
      setContract(contract);
    } else {
    }
  };

  const connectWalletHandler = async () => {
    if(window.ethereum){
      console.log(window.window.ethereum.networkVersion)
      const result = await window.ethereum.request({method: 'eth_requestAccounts'})
      if(window.window.ethereum.networkVersion === process.env.REACT_APP_NETWORK_ID){
        accountChangedHandler(result[0])
        setIsWalletConnected(true)
      }else{
        window.alert("Please switch networks");
      }

    } else {
      window.alert("Please install metamask");
    }
  }

  const accountChangedHandler = async (newAccount) => {
    window.web3 = new Web3(window.ethereum);
    //For development only
    loadBlockchainData()
    setMetamask(true);
    setAccount(newAccount);
  }

  const mint = () => {
    setIsMinting(true);
    if(currentPrice == 0){
      contract.methods
      .FreeMint(count)
      .send({ from: account, value: (count * currentPrice).toString() })
      .once("receipt", (receipt) => {
        setIsMinting(false);
        console.log(receipt)
        contractReads()
      })
      .catch((e) => {
        console.log(e)
      });
    }else{
      contract.methods
      .Mint(count)
      .send({ from: account, value: (count * currentPrice).toString() })
      .once("receipt", (receipt) => {
        setIsMinting(false);
        console.log(receipt)
        contractReads()
      })
      .catch((e) => {
        console.log(e)
      });
    }

  }

  window.ethereum?.on('accountsChanged', accountChangedHandler);
  window.ethereum?.on('chainChanged', ()=>{window.location.reload()});

  return (
    <>
      <main>
        <section id="home">
          <Box sx={{backgroundImage: `url(${borpaBG})` , height: '100vh', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: "cover"}}>
            <div>
              <br />
            </div>
            <div className="mid">
              <Grid>
                <Paper sx={{width: {xs: '99vw', sm:'30vw', lg:'33vw'}, height:{xs: '60vh', md:'70vh'}, marginTop: {xs: '10%', sm: '0'}, opacity: 100, borderRadius: "%", borderStyle: "solid" }}>
                <Description connectWallet={connectWalletHandler} account={account} countHandler={countHandler} remainingFree={remainingFree} maxMintPerAddress={maxMintPerAddress} numberMinted={numberMinted} mintHandler={mint} currentPrice={currentPrice} totalBorpas={totalBorpas} remainingMint={remainingMint} isWalletConnected={isWalletConnected} isMinting={isMinting} />
                </Paper>
              </Grid>
            </div>
            </Box>
        </section>
      </main>
    </>
  );
};

export default App;
