import React, { useState, Component } from "react";

import {
  MenuItem,
  NativeSelect,
  Select,
  FormControl,
  InputLabel,
  Box,
  Button,
  Typography,
  Container,
  Grid,
  Paper,
} from "@mui/material";

import "./App.css";
import Logo from "./images/ce6.png";
import BorpaLick from "./images/BorpaLick.gif";
import WalletCard from "./WalletCard.jsx";
import { FormGroup } from "@mui/material";

export default function Description(props) {
  const [qtySelected, setQtySelected] = useState(1);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");

  function handleSubmit(e) {
    e.preventDefault();
    //need to hook up to smart contract
  }

  function handleChange(e) {
    setQtySelected(e.target.value);
    props.countHandler(e.target.value)
  }

  const availableToMintForAddress = (props.maxMintPerAddress - props.numberMinted)

  return (
    <Container>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <div>
          <img src={BorpaLick} className="logo-img borpaLick" />
          <h1 className="gradient-text description-title">BORPA DAO</h1>
          <p >
            BorpaDAO is a DAO to pay tribute to all Borpas.
            <br/>
            First <b>750 mints</b> are <b>FREE</b>. <b>5</b> Borpas per transaction.{" "}
            <b>3333</b> total Borpa supply. <b>.025/borpa</b>
          </p>
          <div>

          <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Mint</InputLabel>
              <FormGroup>
              <Grid container>
                <Grid xs={6}>
                <Select size='large' sx={{width: '80%', mt: 1}}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={qtySelected}
                  label="Mint"
                  onChange={handleChange}
                  disabled={availableToMintForAddress === 0}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
                </Grid>
                <Grid xs={6}>
                {!props.isWalletConnected && <Button onClick={(e) => {e.currentTarget.blur(); props.connectWallet();}} variant="contained" size="large" type="button" className="btn-xl p-2" color="success">Connect</Button> }
                {props.isWalletConnected &&<Button sx={{ mt: 1, mb: 1 }} onClick={() => props.mintHandler()} disabled={!props.isWalletConnected || availableToMintForAddress === 0} variant="contained" size="large" type="button" color="success" className="btn-xl p-2">
                {" "}
                Mint {qtySelected}{" "}
                </Button> }
              </Grid>
              </Grid>
              </FormGroup>


             {props.isWalletConnected &&
             <>
             <p className="mint-btn1">
               {props.currentPrice * (10**-18) == 0 ? <b>Free mints remaining: {props.remainingFree}</b> : <b>NO Free mints remaining</b> }
             </p>
             {props.currentPrice !== 0 &&
              <p className="mint-btn1">
                <b>{props.remainingMint} / {props.totalBorpas} LEFT AT {props.currentPrice * (10**-18)} ETH</b>
              </p>
              }
             </>
             }
            </FormControl>
          </div>
        </div>
      </Grid>
    </Container>
  );
}